<template>
  <VMenu :anchor="anchor?.$el" :open="open" auto-dismiss class="menu vvd-theme-alternate">
    <div v-for="item in items" :key="item._uid">
      <OneTrustSettingItem v-if="item.link === 'ot-sdk-btn'" :text="item.label" />
      <HyperLink v-else v-slot="{ navigate }" :to="item.link || '#'">
        <VMenuItem
          :text="item.label"
          class="menu-item"
          @click="() => performItemNavigation(navigate, closeNavbarMenu)"
        />
      </HyperLink>
    </div>
  </VMenu>
</template>

<script setup lang="ts">
import { type PropType } from 'vue';
import { VMenu, VMenuItem } from '@vonage/vivid-vue';
import { performItemNavigation } from '@/utils/navigation';
import NavbarButton from '@/components/navbar/common/NavbarButton.vue';
import type { NavigationLinkItem } from '@/types/storyblok';
import HyperLink from '@/components/utils/HyperLink.vue';
import OneTrustSettingItem from '@/components/navbar/common/OneTrustSettingItem.vue';

defineProps({
  open: { type: Boolean, required: true },
  anchor: { type: Object as PropType<InstanceType<typeof NavbarButton>> },
  items: { type: Array as PropType<NavigationLinkItem[]>, required: true },
});

const emit = defineEmits(['close']);

const closeNavbarMenu = () => {
  emit('close');
};
</script>
